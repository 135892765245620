.nav-tabs {
  .nav-item {
    display: block;
    width: 100%;
  }

  .nav-link {
    border-bottom: 1px solid #e3e3e3;
  }
}

.tab-content {
  margin-top: 20px;
}

#overview-content {
  margin-top: 388px;
}

@media (min-width: 576px) {
  .nav-tabs {
    .nav-item {
      display: inline-block;
      width: auto;
    }

    .nav-link {
      border-bottom: 0;
    }
  }

  #overview-content {
    margin-top: 222px;
  }
}

@media (min-width: 768px) {
  #overview-content {
    margin-top: 176px;
  }
}