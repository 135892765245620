.alert {
  font-family: 'Work Sans', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 58px;
  padding-top: 17px;
  z-index: 9999;

  .close {
    margin-top: 6px;
    text-shadow: none;

    &:hover {
      color: $white;
      cursor: pointer;
    }
  }
}

.alert-success {
  background-color: #00465f;
  color: $white;
}

.alert-danger {
  background-color: #ba4c5b;
  color: $white;
}