// Body
$body-bg: #f7f7f7;
$body-color: #484848;

// Typography
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif !important;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$primary-hover: #0FA0E6;
$highlight-gradient: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(251,251,251,1) 15%, rgba(252,252,252,1) 85%, rgba(255,255,255,1) 100%);
$homebase-logo-gradient-1: #4877bb;
$homebase-logo-gradient-2: #1ebdc9;

// Homebase Color Palette
$gray-05: #f5f6f7;
$gray-10: #eee;
$gray-20: #ddd;
$gray-50: #aaa;
$gray-60: #888;
$gray-90: #333;
$blue-05: #F0F5FA;
$orange: #e38044;
$white: #FFFFFF;

$success-green: #33c799;
$warning-orange: #ffa554;
$danger-red: #ba4c5b;

$homebase-blue: #3596d9;
$homebase-blue-alt: #0083c9;
$homebase-dark: #00465f;

$theme-colors: (
  'primary': $homebase-blue,
  'primary-alt': $homebase-blue-alt,
  'primary-dark': $homebase-dark,
  'success': $success-green,
  'danger': $danger-red,
  'warning': $warning-orange,
);
