// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Datepicker
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone.min.css';
// Animate
@import '~animate.css/animate.min.css';
// Work Sans Font
@import url('https://fonts.googleapis.com/css?family=Work+Sans&display=swap');
// Section Styles
@import 'alerts';
@import 'general';
@import 'navbar';
@import 'loading';
@import 'employees';
@import 'buildings';
@import 'portfolios';