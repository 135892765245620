.navbar {
  background-color: $white;
  padding: 0 16px;

  .navbar-brand img {
    width: 160px;
  }

  .navbar-toggler {
    border-radius: 0;
  }

  .navbar-nav {
    margin-top: -6px;
  }

  .nav-link {
    font-weight: 500;
    font-size: 16px;
    margin-right: 30px;
    padding-top: 8px;
    border-top: 5px solid transparent;

    &:first-child {
      margin-left: 30px;
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.router-link-active {
      border-top: 5px solid $homebase-blue;

    }
  }

  .dropdown-menu {
    left: -30px;
    top: 40px;
  }
}

@media (max-width: 992px) {
  .navbar {

    .navbar-collapse {
      border-bottom: 3px solid $homebase-blue;
    }

    .nav-link {
      &.router-link-active {
        border: 0;
      }
    }

    .dropdown-menu {
      background-color: $body-bg;
      border: 0;
      margin-left: 15px;
    }
  }
}