body {
  margin-top: 75px;
  padding-bottom: 25px;
  overflow-x: hidden;
}

.a {
  background-color: transparent;
  color: #3596d9;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 55px;
  right: 0;
  bottom: 0;
}

input[type="checkbox"] {
  vertical-align: top;

  &.form-control {
    height: auto;
    width: auto;
    margin-top: 6px;
  }
}

label {
  font-weight: 700;
  margin-bottom: 5px;
}

textarea {
  display: block;
  width: 100%;
  min-height: 100px;
  max-height: 500px;
  padding: 8px;
  resize: vertical;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.19rem + 0px);
}

.col-0 {
  display: none;
}

.search-box {
  position: fixed;
  top: 52px;
  left: 0;
  right: 0;
  padding-top: 23px;
  z-index: 99;
  background-color: $body-bg;
}

.search-row {
  position: fixed;
  top: 52px;
  padding-top: 20px;
  width: 100%;
  z-index: 99;
  background-color: $body-bg;
}

@media (min-width: 768px) {
  .col-0 {
    display: block;
  }

  .col-md-0 {
    display: none;
  }
}

.alert,
.card,
.dropdown-menu,
.datepicker.dropdown-menu {
  border: 0;
  border-radius: 0;
}

.btn-primary:hover {
  background-color: $primary-hover;
  border-color: $primary-hover;
}

.btn-light {
  background-color: transparent;
  border: 1px solid $homebase-blue;
  color: $homebase-blue;

  &:hover {
    background-color: $homebase-blue;
    border: 1px solid $white;
    color: $white;
  }
}

.btn-remove {
  cursor: pointer;
}

.btn-outline-danger {
  border: 1px solid;
  border-radius: 5px;
}

.card,
.dropdown-menu {
  margin-bottom: 4px;
}

.card {
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

  &.checked {
    background-color: $homebase-blue;
    color: $white;

    &:hover {
      color: $white !important;
    }

    .text-muted {
      color: $white !important;
    }
  }

  .form-control,
  .input-group-text {
    border: 1px solid #ced4da;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 2px;
    margin-bottom: 0;
  }
}

.reset-card {
  .input-group {
    .input-group-text {
      background-color: transparent;
    }

    .form-control {
      border-left: 0;
      border-right: 0;
    }
  }
}

.card-link {
  color: $body-color;

  &:hover {
    color: $body-color !important;
    cursor: pointer;
    text-decoration: none;
  }
}

.card-select {
  &:hover {
    border-color: $homebase-blue !important;
  }
}

.search-box + .content {
  margin-top: 128px;
}

.content {
  display: none;
}

.datepicker-dropdown {
  top: 275px !important;
}

.is-valid {
  border-color: $success !important;
}

.is-invalid {
  border-color: $danger !important;
}

.list-group {
  margin-bottom: 25px;

  .list-group-item {
    border-radius: 0 !important;
    background-color: $body-bg;
    border-left: 0;
    border-right: 0;
    padding-left: .8rem;
    padding-right: .8rem;

    &.router-link {
      cursor: pointer;
    }

    &.active,
    &.router-link-active {
      background-color: $homebase-blue;
      color: $white;
      margin-bottom: 0px;
    }

    .step {
      font-weight: bold;
      margin-right: 10px;
    }
  }
}

.logo {
  display: block;
  margin: 60px auto 30px auto;
  width: 300px;
}

.modal-content {
  border: 0;
  border-radius: 0;
  cursor: auto;

  .card {
    border: 1px solid #ced4da;

    &:hover {
      box-shadow: 0 11px 15px 0 rgba(0, 0, 0, .02);
    }

    .card-body {
      padding: .5rem 1rem;

      .box {
        width: 100%;
        margin-left: 10px;
        position: absolute;
        z-index: 99;

        p {
          margin-bottom: 10px;
        }
      }
    }
  }

  .form-control,
  .input-group-text {
    border: 1px solid #ced4da;
    height: 34px;
  }
}

.modal-footer .btn {
  padding: 10px 35px;
  border-radius: 3px;
}

@media (max-width: 992px) {
  .modal-footer .btn {
    margin-top: 5px;
    width: 100%;
  }
}

.nav-tabs {
  border-bottom: 1px solid #979797;

  .nav-link {
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      border-color: transparent;
      color: #343434;
      cursor: pointer;
    }

    &.active {
      border: 0;
      border-bottom: 2px solid $black;
      font-weight: bold;
    }
  }
}

.paginate-links {
  margin-top: 10px;
  padding-left: 0;
  text-align: center;

  li {
    background-color: $white;
    color: $homebase-blue;
    cursor: pointer;
    display: inline-block;
    height: 37px;
    list-style: none;

    a {
      display: block;
      padding: 7px 12px;
    }

    &:hover {
      background-color: #eff3f6;
    }

    &.active {
      background-color: $homebase-blue;
      color: $white;
    }
  }
}

.fob-table-header {
  border-bottom: 2px solid #dee2e6
}

.fob-table-border {
  border-top: 1px solid #dee2e6;
}

.scroll-box {
  max-height: 180px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.subtitle {
  margin-top: -5px;
  font-size: 13px;

  a {
    color: $body-color;

    &:hover {
      color: $body-color;
      text-decoration: none;
    }
  }
}

.active-warning,
.expired-warning,
.used-warning {
  width: 15px !important;
}

.expired-warning {
  color: #e38044;
}

.active-warning {
  color: #cb5b54;
}

.used-warning {
  color: #4bb543;
}
