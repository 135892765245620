.building-nav {
  margin-bottom: 15px;
  padding: 0;

  .navbar-collapse {
    border-bottom: 0;
  }

  .nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, .125);

    &:first-child {
      border-top: 1px solid rgba(0, 0, 0, .125);
    }

    .nav-link {
      border-top: none;
      color: $body-color;
      font-weight: 400;
      font-size: $font-size-base;
      padding: 0.6rem 1.25rem;

      &:first-child {
        margin-left: 0;
      }
    }

    .router-link-active {
      background-color: #3596d9;
      border-top: 0;
      color: $white;
    }
  }
}

#edit-btn {
  margin-top: -8px;
}

.building-form {
  margin-bottom: 50px;
  margin-top: -6px;

  h4 {
    margin-bottom: 15px;
  }

  .box {
    width: 100%;
    position: absolute;
    z-index: 99;

    p {
      margin-bottom: 25px;
    }
  }
}

.feature {
  cursor: pointer;

  label {
    cursor: pointer;
  }

  &.disabled {
    cursor: default;

    label {
      cursor: default;
    }

    .feature-check svg {
      color: $body-color;
    }
  }

  label {
    margin-left: 25px;
    margin-bottom: 0;
  }

  .row {
    margin-left: 11px;
  }

  .feature-check {
    position: absolute;
    left: 9px;

    svg {
      color: $homebase-blue;
    }

    .hide {
      display: none;
    }
  }

  .feature-check-background {
    position: absolute;
    left: 6px;
    background: white;
    height: 22px;
    width: 21px;
    border-radius: 4px;
  }
}

.totals {
  text-align: right;
}

.total {
  h5 {
    margin-bottom: 0;
  }

  small {
    display: block;
    margin-top: -4px;
  }
}

.inline-total-reverse {
  display: none;
}

.btn-edit {
  display: block;
  margin: 0 auto 15px;
}

.building-small {
  display: block;
  margin-top: -6px;
}

.building-footer {
  background-color: $white;
  bottom: 0;
  left: 0;
  padding: 1rem;
  position: fixed;
  right: 0;
  z-index: 100;
}

.maintenance-category {
  padding: .5rem 1rem;
}

.tier-image {
  margin-top: -9px;
  width: 28px;
  height: 28px;
}

.tier-image-select {
  width: 48px;
  height: 48px;
  margin-top: 5px;
  margin-right: 10px;
}

.image-radio {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;

  & + img {
    cursor: pointer;
  }

  &:checked + img {
    box-shadow: 0 0 0 3px #fff, 0 0 0 6px #3596d9;
    border-radius: 50%;
  }
}

@media (min-width: 768px) {
  .btn-edit {
    position: absolute;
    top: 54px;
    right: 15px;
    padding: 8px 20px;
    z-index: 99;
  }

  .inline-total {
    display: none;
  }

  .inline-total-reverse {
    display: block;
  }

  .totals {
    text-align: left;
  }
}
